.sub-category-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    padding: 20px;
    background-color: #f8f9fa; /* Light background for contrast */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Container shadow */
    margin: 20px; /* Add margin for spacing */
}

.category-title {
    font-size: 24px; /* Title size */
    margin-bottom: 20px; /* Space below the title */
    color: #333; /* Title color */
}

.sub-category-list {
    list-style-type: none; /* Remove default list style */
    padding: 0; /* Remove default padding */
    width: 100%; /* Make list responsive */
    max-width: 600px; /* Limit maximum width */
}

.sub-category-item {
    background-color: #fff; /* Background color for each item */
    padding: 15px; /* Padding for list items */
    margin: 10px 0; /* Margin between items */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
    transition: box-shadow 0.3s; /* Transition for hover effect */
}

.sub-category-item:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}
