/* สไตล์ทั่วไปสำหรับ Header */
.New-container {
  position: fixed;      /* คงที่ที่ตำแหน่งด้านบน */
  top: 0;               /* คงที่ที่ขอบบนสุด */
  left: 0;
  right: 0;
  z-index: 1000;        /* ทำให้ Navbar อยู่เหนือส่วนอื่น */
  background-color: #fff; /* สีพื้นหลังให้ดูเด่น */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* เพิ่มเงาให้ดูเด่นขึ้น */
  padding: 10px 20px;
  transition: all 0.3s ease;  /* ให้มี transition เมื่อมีการเปลี่ยนตำแหน่ง */
}

body {
  padding-top: 70px;  /* เพิ่มพื้นที่บนสุดให้พอสำหรับ navbar ที่คงที่ */
}
.logo-wrapper {
  flex-grow: 1;
  text-align: center;
}

.logo-image {
  max-width: 100%;
  height: auto;
  max-width: 400px;
}

.user-cart-container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%; /* เพิ่ม width ให้เต็มพื้นที่ */
}

/* ปรับตำแหน่งของ search ให้ชิดขวา */
.search-container {
  margin-left: auto; /* ทำให้ search ชิดขวา */
}

.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
}

.nav-link:hover {
  background-color: #dd0c86cb;
  color: white;
}

/* เพิ่ม position relative เพื่อให้ dropdown แสดงตำแหน่งถูกต้อง */
.user-dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative; /* เพิ่มการตั้งตำแหน่งให้กับ parent */
}

.user-dropdown .dropdown-menu {
  display: none;
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
}

.user-dropdown:hover .dropdown-menu {
  display: block;
}

.user-dropdown span {
  font-weight: bold;
}

.user-dropdown button {
  background: none;
  border: none;
  color: #630505;
  cursor: pointer;
}

/* สำหรับมือถือ */


@media (max-width: 768px) {
  .New-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-wrapper {
    margin-bottom: 10px;
  }

  .user-cart-container {
    margin-top: 10px;
    gap: 15px;
    font-size: 5px
    
  }

  /* ปรับขนาดไอคอนและข้อความเมื่ออยู่บนมือถือ */
  

  .logo-image {
    max-width: 250px; /* ลดขนาดโลโก้ */
  }
}