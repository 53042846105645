.shadow-box {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* ปรับค่าเงาขอบ */
    border-radius: 10px; /* เพิ่มความโค้งของขอบ */
    padding: 20px; /* เพิ่มระยะห่างภายใน */
    background-color: #fff; /* พื้นหลังสีขาว */
  }
  
  .typing-effect {
    color: #FF1493; /* เปลี่ยนสีข้อความ */
  }
  