/* สไตล์ทั่วไป */
.category-row {
  display: flex;
  flex-wrap: wrap; /* ทำให้หมวดหมู่ห่อไปในแถวใหม่หากพื้นที่ไม่พอ */
  justify-content: space-between; /* กระจายการ์ดให้พอดี */
}

.category-card-container {
  flex: 1 1 calc(10% - 10px); /* แสดง 4 การ์ดในแถวในขนาดหน้าจอปกติ */
  margin-right: 5px; /* ระยะห่างระหว่างการ์ด */
  margin-bottom: 5px; /* ระยะห่างระหว่างการ์ด */
}

.category-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  height: 150px;
    width: 150px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-size: cover;
  background-position: center 15%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
}

.category-link {
  color: #630505;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  margin-top: 10%;
  background-color: rgba(46, 10, 10, 0.5);
  border-radius: 10px;
  padding: 10px;
}

/* ปรับแสดงผลบนมือถือ */
@media (max-width: 1200px) {
  .category-card-container {
    flex: 1 1 calc(50% - 10px); /* แสดง 2 การ์ดในแถวเมื่อขนาดหน้าจอเล็กลง */
    margin-bottom: 15px; /* เพิ่มระยะห่างระหว่างการ์ด */
  }
  .category-card {
    height: 100px; /* ปรับความสูงการ์ด */
    width: 100px;
  }
  /* .category-card {
    height: 180px; 
  } */
}

@media (max-width: 576px) {
  .category-card-container {
    flex: 1 1 100%; /* แสดง 1 การ์ดในแถวเมื่อขนาดหน้าจอเล็กสุด */
    margin-bottom: 15px; /* เพิ่มระยะห่างระหว่างการ์ด */
  }

  .category-card {
    height: 80px; /* ปรับความสูงการ์ด */
    width: 80px;
  }

  .category-link {
    font-size: 14px; /* ขนาดข้อความเล็กลงสำหรับมือถือ */
    padding: 5px;
  }
  .category-link {
    color: #630505;
    font-size: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    margin-top: 80%;
    background-color: rgba(46, 10, 10, 0.5);
    border-radius: 10px;
    padding: 10px;
    height: 10px; /* ปรับความสูงการ์ด */
      width: 50px;
  }
  .category-card-container {
    flex: 1 1 calc(10% - 20px); /* แสดง 4 การ์ดในแถวในขนาดหน้าจอปกติ */
    margin-right: 24px; /* ระยะห่างระหว่างการ์ด */
    margin-bottom: 3px; /* ระยะห่างระหว่างการ์ด */
  }
  
}

