/* src/components/page/MenuUserHGome/ProductList.css */

.product-list-container {
    padding: 20px;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* กำหนดระยะห่างระหว่างการ์ด */
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    width: 200px; /* กำหนดความกว้างของการ์ด */
}

.product-card img {
    max-width: 100%; /* ทำให้ภาพมีขนาดเต็มที่ภายในการ์ด */
    height: auto;
    border-radius: 4px; /* มุมโค้งมนของภาพ */
}
