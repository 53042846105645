.box-shadow {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff;
}

/* .blinking-box {
  border: 2px solid #2c9429; 
  padding: 10px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
  animation: blinking 2s infinite;
  text-align: center;
  width: fit-content;
  margin: 0 auto; 
} */
.Card-Product {
  background: linear-gradient(50deg, #d6080b, #f18425); /* ไล่ระดับสี */
  color: white; /* สีตัวหนังสือเป็นสีขาว */
  border: none; /* ลบขอบปกติ */
  padding: 10px 20px; /* เพิ่ม padding ให้ปุ่ม */
  font-size: 16px; /* ขนาดตัวอักษร */
  transition: background 0.3s; /* เพิ่มการเปลี่ยนแปลงเมื่อชี้เมาส์ */
  animation: blink-animation 1s steps(5, start) infinite; 
}

/* Animation กระพริบ */
@keyframes blinking {
  0% { background-color: #2c9429; color: #fff; } 
  50% { background-color: #fff; color: #2c9429; } 
  100% { background-color: #2c9429; color: #fff; } 
}

/* ใช้ Flexbox ในการจัดให้ตรงกลางแนวตั้ง */
.center-product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* ปรับความสูงตามต้องการ */
  background-color: #ffffff;
}


.container{
  background-color: #ffffff;
}

/* Home.css
.home-container {
padding: 0;
}

@media (max-width: 768px) {
.home-container {
  padding: 10px;
}

.text-center p {
  font-size: 14px;
}

.img-fluid {
  max-width: 100%;
}
}

@media (max-width: 576px) {
.blinking-box {
  font-size: 18px;
}
} */


.content-container {
display: flex;
}

/* Style the sidebar (menu) */
.sidebar-container {
width: 250px; /* กำหนดความกว้างของเมนูด้านข้าง */
background-color: #f8f9fa; /* สีพื้นหลังของเมนู */
}

/* Style the carousel area */
.carousel-container {
display: flex;
justify-content: center; /* จัดให้อยู่ตรงกลางในแนวนอน */
align-items: center;     /* จัดให้อยู่ตรงกลางในแนวตั้ง */
         /* ให้ความสูง 100% ของ viewport */
margin: 0 auto;         /* จัดให้อยู่กลางถ้าจำเป็น */
}

.row.container {
width: 100%; /* ทำให้แนวนอนเต็มพื้นที่ */
}

.image-slide img {
width: 100%;        /* ทำให้รูปภาพเต็มความกว้าง */
height: auto;      /* ทำให้สูงเป็นอัตโนมัติตามสัดส่วน */
max-height: 400px; /* ปรับความสูงสูงสุดตามต้องการ */
object-fit: cover;  /* ทำให้รูปภาพเต็มพื้นที่ โดยไม่เปลี่ยนสัดส่วน */
}

.menu {
  box-shadow: 0 4px 8px rgba(179, 27, 128, 0.3); /* เพิ่มเงา */
  border-radius: 8px; /* ปรับให้ขอบมีความมน */
  padding: 10px; /* ปรับระยะห่างด้านใน */
  background-color: #ffffff; /* ให้พื้นหลังเป็นสีขาวเพื่อให้ดูชัดเจน */
  display: flex; /* ใช้ flexbox สำหรับการจัดการการแสดงผล */
  flex-wrap: wrap; /* เพิ่มการห่อถ้าเมนูยาวเกินไป */
  justify-content: space-around; /* จัดตำแหน่งให้กระจาย */
}

@media (min-width: 768px) {
  .menu {
    display: flex; /* ใช้ flexbox */
    justify-content: space-between; /* จัดตำแหน่งให้กระจาย */
  }
}

@media (max-width: 576px) {
  .menu {
    display: block; /* เปลี่ยนเป็น block เมื่อหน้าจอเล็ก */
  }
}








.custom-headingHotProduct-container {
  position: relative;
  background-image: url('./images/image-header-product/TabBar 1_0.png'); 
  background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
  background-position: center; /* จัดภาพให้กลาง */
  height: 180px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
  display: flex;
  justify-content: center;
  align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
}
.custom-headingNewProduct-container {
  position: relative;
  background-image: url('./images/image-header-product/TabBar 3_0.png'); 
  background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
  background-position: center; /* จัดภาพให้กลาง */
  height: 180px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
  display: flex;
  justify-content: center;
  align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
}
.custom-headingAllProduct-container {
  position: relative;
  background-image: url('./images/image-header-product/TabBar 4_0.png'); 
  background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
  background-position: center; /* จัดภาพให้กลาง */
  height: 180px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
  display: flex;
  justify-content: center;
  align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
}
.custom-headingDonate-container {
  position: relative;
  background-image: url('./images/image-header-product/TabBar 5_0.png'); 
  background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
  background-position: center; /* จัดภาพให้กลาง */
  height: 180px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
  display: flex;
  justify-content: center;
  align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
}


.custom-box {
  width: 100%;
  height: 200px;
  border: 1px solid #cdcdcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #bababa, #ffffff); /* ไล่เฉดสีจากชมพูไปครีม */
  margin-top: 1rem;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.25);
}


.custom-text {
  margin: 0;
  font-size: 1rem;
  text-align: center;
  animation: blink 3s infinite; /* ทำให้ข้อความกระพริบ */
}

.custom-text:first-child {
  font-size: 1.2rem;
  font-weight: bold;
}

.custom-text:nth-child(2) {
  color: #ff0000;
}

@keyframes blink {
  0%, 100% {
    opacity: 1; /* เริ่มต้นและสิ้นสุดเป็นมองเห็น */
  }
  50% {
    opacity: 0; /* กลางคาบหายไป */
  }
}



@media (max-width: 576px) { /* เมื่อหน้าจอมีขนาดสูงสุดที่ 576px (Mobile) */
  .responsive-logo {
    max-width: 30px; /* ลดขนาดสูงสุดของรูปภาพเป็น 30px */
  }

  .custom-headingHotProduct-container {
    position: relative;
    background-image: url('./images/image-header-product/TabBar 1_0.png');
    background-position: center; /* จัดภาพให้กลาง */
    background-size: cover; /* ปรับขนาดให้ภาพครอบคลุมพื้นที่ (สามารถปรับเป็น contain ถ้าต้องการให้ภาพทั้งหมดแสดง) */
    height: 50px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    width: 130px; 
  }

  .custom-headingNewProduct-container {
    position: relative;
    background-image: url('./images/image-header-product/TabBar 3_0.png');
    background-position: center; /* จัดภาพให้กลาง */
    background-size: cover; /* ปรับขนาดให้ภาพครอบคลุมพื้นที่ */
    height: 50px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    width: 150px; 
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }

  .custom-headingAllProduct-container {
    position: relative;
    background-image: url('./images/image-header-product/TabBar\ 4_0.png');
    background-position: center; /* จัดภาพให้กลาง */
    background-size: cover; /* ปรับขนาดให้ภาพครอบคลุมพื้นที่ */
    height: 50px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    width: 120px; 
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  .custom-headingDonate-container {
    position: relative;
    background-image: url('./images/image-header-product/TabBar\ 5_0.png'); 
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 50px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    width: 120px; 
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
 
  .custom-text:first-child {
    font-size: 0.4rem;
    font-weight: bold;
  }

  .custom-text {
    margin: 0;
    font-size: 0.6rem;
    text-align: center;
    animation: blink 3s infinite; /* ทำให้ข้อความกระพริบ */
  }
  .custom-box {
    width: 100%;
    height: 100px;
    border: 1px solid #cdcdcd; /* เส้นขอบบางลง */
    display: flex;
    flex-direction: column; /* จัดข้อความในแนวตั้ง */
    align-items: center; /* จัดข้อความให้อยู่ตรงกลางแนวนอน */
    justify-content: center; /* จัดข้อความให้อยู่ตรงกลางแนวตั้ง */
    background-color: #ffffff; /* สีพื้นหลัง */
    margin-top: 1rem; /* เว้นระยะด้านบน */
    padding: 10px; /* เว้นระยะในกล่อง */
    box-sizing: border-box; /* รวม padding ในขนาดกล่อง */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.25); /* เพิ่มความเข้มของเงา */
  }
}



.blinking-text {
animation: blink-animation 1s steps(5, start) infinite; /* ตั้งค่าการกระพริบ */
color: rgb(255, 0, 221); /* กำหนดสีของตัวหนังสือ */
}

@keyframes blink-animation {
to {
  visibility: hidden; /* ทำให้ข้อความมองไม่เห็น */
}
}


.blinking-button {
  background: linear-gradient(50deg, #a10560, #fa4ef1); /* ไล่ระดับสี */
  color: white; /* สีตัวหนังสือเป็นสีขาว */
  border: none; /* ลบขอบปกติ */
  padding: 10px 20px; /* เพิ่ม padding ให้ปุ่ม */
  font-size: 16px; /* ขนาดตัวอักษร */
  transition: background 0.3s; /* เพิ่มการเปลี่ยนแปลงเมื่อชี้เมาส์ */
  animation: blink-animation 1s steps(5, start) infinite; /* เพิ่มการกระพิบ */
}

@keyframes blink-animation {
  0%, 100% {
    opacity: 1; /* ความโปร่งใส 100% */
  }
  50% {
    opacity: 0.7; /* ความโปร่งใส 50% */
  }
}