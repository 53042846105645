/* src/components/layouts/Header.css */
header {
  background-image: url('./img/Header3.png');
  background-size: cover;
  background-position: center;
  color: white; /* เพื่อให้ข้อความใน Header มองเห็นได้ชัดเจนบนรูปภาพ */
  padding: 10%;
  text-align: center;
  position: relative; /* ให้ Header เป็นตำแหน่ง relative */
  z-index: 2; /* ตั้งค่า z-index ให้สูงกว่า Navbar */
}

/* nav ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}
@media (max-width: 768px) {
  header {
    height: 180px; 
  }
} */