/* styles.css */
.custom-h2 {
    font-family: 'Kanit', sans-serif; /* เปลี่ยนเป็นฟอนต์ที่คุณต้องการ */
    color: #3d3d3d; /* เปลี่ยนเป็นสีที่คุณต้องการ */
}

.custom-h4 {
    font-family: 'Kanit', sans-serif; /* เปลี่ยนเป็นฟอนต์ที่คุณต้องการ */
    color: #C70039; /* เปลี่ยนเป็นสีที่คุณต้องการ */
}

/* ProductDetails.css */
/* ProductDetails.css */
.product-details-container {
    background-color: white; /* สีพื้นหลังของ container */
    border: 1px solid #ddd; /* ขอบ */
    border-radius: 8px; /* มุมมน */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* เงา */
    padding: 20px; /* ระยะห่างภายใน */
    margin: 20px auto; /* ระยะห่างด้านบนและล่าง พร้อมจัดกลาง */
    max-width: 1100px; /* กำหนดความกว้างสูงสุดของ container */
}

/* กำหนดสีพื้นหลังสำหรับด้านนอก container */

