.best-seller-container {
  margin-top: 20px;
}
.youencare-container {
  margin-top: 20px;
}

.horizontal-scroll-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scrollbar-width: thin; /* For Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.horizontal-scroll {
  display: flex;
  gap: 10px; /* Space between product cards */
}

.horizontal-scroll-item {
  flex: 0 0 auto;
  width: 200px; /* Set a fixed width for each card */
}
