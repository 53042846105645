
.card-Allmember {
    position: relative;
    background-image: url('../../images/card/card.jpg'); 
    background-size: cover;
    background-position: center;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px; /* ทำขอบมน */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* เพิ่มเงา */
    overflow: hidden; /* ป้องกันเนื้อหาเกินขอบมน */
}
@media (max-width: 576px) {

    .tiltlecard{
        margin-top: 40px;
    }
    .card-Allmember {
        position: relative;
        background-image: url('../../images/card/card.jpg'); 
        background-size: cover;
        background-position: center;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px; /* ทำขอบมน */
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* เพิ่มเงา */
        overflow: hidden; /* ป้องกันเนื้อหาเกินขอบมน */
        margin-top: 20px;
    }
}