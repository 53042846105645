.custom-heading11-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/GocerlePC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading22-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/HealthPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading33-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/MomPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading44-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/VitaPC.png'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading55-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/MeficaPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading66-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/BeautyPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading77-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/OrganigPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
/* .custom-heading88-container {
    position: relative;
    background-image: url('./imageProduct/imagePC/'); 
    background-size: cover; 
    background-position: center; 
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center; 
  } */
  
  .custom-heading {
    color: white; /* สีของข้อความ */
    font-size: 30px; /* ขนาดของข้อความ */
    font-weight: bold; /* ทำให้ข้อความหนา */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* ทำให้ข้อความเด่นขึ้น */
    animation: blink 1s infinite; /* เพิ่ม animation ให้ข้อความกระพริบ */
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  

  @media (max-width: 768px) {
    .custom-heading11-container {
      position: relative;
      background-image: url('./imageProduct/Groceries.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading22-container {
      position: relative;
      background-image: url('./imageProduct//Health.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading33-container {
      position: relative;
      background-image: url('./imageProduct/Mom.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }

    .custom-heading44-container {
      position: relative;
      background-image: url('./imageProduct/Vitamins\ &\ Supplements.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }

    .custom-heading55-container {
      position: relative;
      background-image: url('./imageProduct/Medical.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading66-container {
      position: relative;
      background-image: url('./imageProduct/Beauty\ Product.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading77-container {
      position: relative;
      background-image: url('./imageProduct/OrganicProduct.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading88-container {
      position: relative;
      background-image: url('./image/CareUHome.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
  }