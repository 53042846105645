/* ปรับสไตล์ของปุ่ม Navbar.Toggle */
.custom-navbar-toggle {
  border: none; /* ลบขอบของปุ่ม */
  background-color: transparent; /* ให้ปุ่มพื้นหลังโปร่งใส */
}

/* ไอคอน 3 ขีด */
.custom-navbar-toggle .navbar-toggler-icon {
  background-color: black; /* ทำให้ 3 ขีดมีสีดำ */
  width: 30px; /* ความกว้างของไอคอน */
  height: 3px; /* ความหนาของไอคอน */
  border-radius: 2px; /* ให้ขีดมีมุมมน */
}

/* ปรับข้อความ MENU */
.menu {
  display: none; /* ซ่อนข้อความนี้ในขนาดหน้าจอปกติ */
  color: #000000;
  font-size: 10px;
  font-weight: bold;
}
.navbar-custom {
  /* background-color: #377e2e8c;  */
  /* ไล่จากบนลงล่าง */
background-image: linear-gradient(to right, rgba(206, 29, 97, 0.699), rgb(125, 219, 125), rgba(79, 199, 236, 0.705));

  color: #ffffff;
  
  top: 0;
  
  width: 100%;
}

body {
  padding-top: 140px;  /* เพิ่มพื้นที่ด้านบนให้พอสำหรับทั้ง NavbarHead และ ResponsiveNavbar */
}

.navbar-custom {
  position: fixed;       /* คงที่ที่ตำแหน่ง */
  top: 95px;             /* อยู่ใต้ NavbarHead โดยกำหนดระยะห่าง */
  left: 0;
  right: 0;
  z-index: 999;          /* ให้แน่ใจว่า Navbar นี้อยู่เหนือเนื้อหาหลัก */
  background-color: #fff;  /* ตั้งสีพื้นหลัง */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* เพิ่มเงาให้ดูเด่น */
  padding: 10px 0;
}

.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* ทำให้เป็นวงกลม */
  object-fit: cover;
  margin-right: 8px; /* ระยะห่างระหว่างรูปและชื่อ */
}

.searchnav{
  background-color: #353535;
  top: 5px;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.user-dropdown .anticon {
  color: #aaa; /* สีไอคอนเมื่อไม่มีรูป */
}
/* เมื่อหน้าจอเล็กกว่าหรือเท่ากับ 768px */
@media (max-width: 957px) {
  .custom-navbar-toggle .navbar-toggler-icon {
    background-color: black;
  }

  .menu {
    display: block; /* แสดงข้อความ MENU บนมือถือ */
    color: rgb(46, 45, 45);
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px; /* เว้นระยะเล็กน้อยจาก 3 ขีด */
  }

  .custom-navbar .navbar-collapse {
    flex-direction: column; /* ทำให้เมนูเรียงจากบนลงล่าง */
  }

  .custom-navbar .nav-link {
    text-align: center; /* จัดตำแหน่งเมนูให้ตรงกลาง */
    padding: 50px; /* เพิ่มระยะห่างในเมนู */
  }

  .custom-navbar .nav-link:hover {
    background-color: #dd0c86cb;
    color: white;
  }
}

/* สำหรับหน้าจอเล็กสุด (มือถือ) */
@media (max-width: 576px) {
  .custom-navbar .navbar-collapse {
    padding-top: 20px; /* เพิ่มพื้นที่บนสุด */
  }

  .custom-navbar .nav-link {
    font-size: 16px; /* ปรับขนาดฟอนต์ให้ใหญ่ขึ้นสำหรับมือถือ */
    padding: 12px 10px;
  }

  .custom-navbar .nav-link:hover {
    background-color: #dd0c86cb;
    color: white;
  }
  .navbar-custom {
    top: 75px; /* ลดระยะห่างลงในมือถือ */
  }

  .profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%; /* ทำให้เป็นวงกลม */
    object-fit: cover;
    margin-right: 8px; /* ระยะห่างระหว่างรูปและชื่อ */
  }
  /* body {
    padding-top: 130px; 
  } */
}
