.custom-button {
    background-color: #3498db; /* สีฟ้า */
    color: white; /* สีตัวอักษร */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  .custom-button:hover {
    background-color: #2980b9; /* เปลี่ยนสีเมื่อ hover */
  }
  