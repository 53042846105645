.center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

h4{
    
        font-family: "Kanit", sans-serif;
        font-weight: 200;
        font-style: normal;
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
      
}

@media (min-width: 768px) {
  .col-md-12 img {
    width: 100%;
  }
}