.tab-background {
    background-color: #ffffff; /* สีพื้นหลังที่ต้องการ */
    padding: 20px; /* เพิ่มการเว้นวรรคภายใน */
    border-radius: 5px; /* มุมมน */
  }
  
  .tab-background .nav-link {
    background-color: #007bff; /* สีพื้นหลังของแท็บ */
    color: white; /* สีตัวอักษรในแท็บ */
  }
  
  .tab-background .nav-link.active {
    background-color: #0056b3; /* สีพื้นหลังของแท็บที่เลือก */
  }

  .partner-heading {
    color: #0066cc; /* เลือกสีที่ต้องการ */
    font-weight: bold;
    font-size: 25px; /* กำหนดขนาดตัวหนังสือเป็น 2rem */
  }
  
  .partner-description {
    color: #333; /* เลือกสีที่ต้องการ */
    font-size: 1.2rem; /* กำหนดขนาดตัวหนังสือเป็น 1.2rem */
    margin-top: 10px;
  }
  

  .custom-headPart-container {
    position: relative;
    background-image: url('../layouts/img/Header2.png'); 
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 400px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }

  .btn.custom-btn {
    background: linear-gradient(50deg, #d6080b, #f18425); /* ไล่ระดับสี */
    color: white; /* สีตัวหนังสือเป็นสีขาว */
    border: none; /* ลบขอบปกติ */
    padding: 10px 20px; /* เพิ่ม padding ให้ปุ่ม */
    font-size: 16px; /* ขนาดตัวอักษร */
    transition: background 0.3s; /* เพิ่มการเปลี่ยนแปลงเมื่อชี้เมาส์ */
    animation: blink-animation 1s steps(5, start) infinite; /* การกระพริบของปุ่ม */
  }
  
  /* การเปลี่ยนแปลงเมื่อชี้เมาส์ไปที่ปุ่ม */
  .custom-btn:hover {
    background: linear-gradient(50deg, #f18425, #d6080b); /* เปลี่ยนสีเมื่อ hover */
  }
  
  /* การกำหนด animation สำหรับปุ่ม */
  @keyframes blink-animation {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  @media (max-width: 576px) {
    .custom-headPart-container {
      position: relative;
      background-image: url('../layouts/img/Header2.png'); 
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 120px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
  
  }