.home-container {
  margin-top: 20px;
}

.home-scroll-container {
  display: flex;
  overflow-x: auto; /* ให้ scroll แนวนอน */
  padding: 10px 0;
  scroll-snap-type: x mandatory; /* เพิ่มการตั้งค่าให้สามารถเลื่อนได้ */
}

.home-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* สีของ thumb */
  border-radius: 10px; /* ทำให้มุมของ scrollbar ป้าน */
}

.home-scroll-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* สีของ track (ที่เป็นพื้นหลังของ scrollbar) */
}

.home-scroll {
  display: flex;
  flex-wrap: nowrap; /* ใช้ wrap เพื่อไม่ให้ card หยุดลง */
  gap: 50px; /* เพิ่มระยะห่างระหว่างการ์ด */
}

.home-scroll-item {
  flex: 0 0 auto; /* ป้องกันไม่ให้ item ขยายเต็มที่ */
  scroll-snap-align: start; /* ทำให้การ์ดยึดตามการเลื่อน */
  margin-right: 100px;
}

@media (max-width: 576px) {
  .home-scroll-container {
      grid-template-columns: repeat(2, 1fr); /* แสดง 2 คอลัมน์สำหรับมือถือ */
  }

  .product-card-container {
      max-width: 150px; /* ปรับขนาดของ card */
  }
}
