@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&family=Trirong:wght@400;700&display=swap');

body {
  font-family: 'Sarabun', serif;
}

.shop-card-container {
  position: relative;
  width: 100%;
  max-width: 250px; 
  margin: auto;
}

.shop-price {
  display: flex;
  justify-content: space-between; /* จัดให้ชิดซ้าย-ขวา */
  align-items: baseline; /* จัดให้ข้อความของ h4 และ p อยู่ในแนวเดียวกัน */
  width: 100%;
  padding: 0 5px;
  flex-wrap: nowrap; /* บังคับไม่ให้ข้อความตกบรรทัด */
}

.shop-price h4 {
  margin: 0; /* ลด margin ของ h4 */
  font-size: 14px;
  color: #4CAF50;
  font-weight: bold;
  text-align: right; /* ชิดซ้าย */
  flex-grow: 1; /* ให้ h4 ใช้พื้นที่แนวนอนมากขึ้น */
  padding: 5px;
}

.shop-price p {
  margin: 0; /* ลด margin ของ p */
  font-size: 12px; /* ปรับขนาดฟอนต์ให้เหมาะสม */
  white-space: nowrap; /* ป้องกันไม่ให้ข้อความใน p ตกบรรทัด */
  color: #e12121;
}

.promotion-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  z-index: 1;
  animation: blink 1s infinite;
}

.product-image {
  height: 240px;
  width: 100%;
}
.shop-price{
  font-size: 12px;
  font-family: 'Sarabun', serif;
}

.action-icon {
  font-size: 50px; /* ลดขนาด icon */
  margin: 0 5px; /* กระชับช่องว่างระหว่าง icon */
  display: flex;
  align-items: center;
  justify-content: center;

}

.shop-card-container .ant-card-actions {
  margin-top: 1px;
  
}

/* .shop-card-container .actions.action-shopping-cart {
  margin-top: 1px;
  color: #4CAF50;
  
} */
.shop-card-container .ant-card-meta-title {
  font-size: 16px
  
}

.ant-card-actions > li {
  margin-top: 10px; /* ลบ margin ของรายการใน actions */
}

.shop-card-container .ant-card-body{
  margin-top: 3px;
  padding: 10px;
}

@keyframes blink {
  0% { background-color: red; }
  50% { background-color: pink; }
  100% { background-color: red; }
}

.shop-price h4 {
  margin-top: 3px;
  color: #4CAF50;
  font-weight: bold;
  font-size: 12px; 
}

/* .product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px; 
} */

@media (max-width: 576px) {
 
  .shop-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px; 
    width: 100%;
  }

  .product-image {
    height: 180px; /* ตั้งความสูงใหม่สำหรับหน้าจอมือถือ */
  }

  .action-icon {
    font-size: 14px; /* ลดขนาด icon เพิ่มเติมสำหรับมือถือ */
  }

  .shop-card-container .ant-card-meta-title {
    font-size: 12px;
    margin: 0px 0px 0px;
    
  }
  .shop-card-container .ant-card-meta-description {
    font-size: 10px;
    margin-top: 1px;
     
  }
}


