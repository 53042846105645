.custom-heading1-container {
    position: relative;
    background-image: url('./image/PC/RehabPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading2-container {
    position: relative;
    background-image: url('./image/PC/AestheticPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
.custom-headingIV-container {
    position: relative;
    background-image: url('./image/PC/IV.png'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading3-container {
    position: relative;
    background-image: url('./image/PC/MedicalspaPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading4-container {
    position: relative;
    background-image: url('./image/PC/HealCheckPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
.custom-headingRegen-container {
    position: relative;
    background-image: url('./image/PC/RegenPC.png'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading5-container {
    position: relative;
    background-image: url('./image/PC/FitnessPC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading6-container {
    position: relative;
    background-image: url('./image/PC/Mental.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading7-container {
    position: relative;
    background-image: url('./image/Wellness.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
.custom-heading8-container {
    position: relative;
    background-image: url('./image/PC/CareUHomePC.jpg'); /* ตั้งค่าภาพพื้นหลัง */
    background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
    background-position: center; /* จัดภาพให้กลาง */
    height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
    display: flex;
    justify-content: center;
    align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
  }
  
  .custom-heading {
    color: white; /* สีของข้อความ */
    font-size: 30px; /* ขนาดของข้อความ */
    font-weight: bold; /* ทำให้ข้อความหนา */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* ทำให้ข้อความเด่นขึ้น */
    animation: blink 1s infinite; /* เพิ่ม animation ให้ข้อความกระพริบ */
  }
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @media (max-width: 768px) {
    .custom-heading1-container {
      position: relative;
      background-image: url('./image/Rehab.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading2-container {
      position: relative;
      background-image: url('./image/Aesthe.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading3-container {
      position: relative;
      background-image: url('./image/Midical.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading4-container {
      position: relative;
      background-image: url('./image/HealthCheck.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading5-container {
      position: relative;
      background-image: url('./image/Fitness.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading6-container {
      position: relative;
      background-image: url('./image/Mental.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading7-container {
      position: relative;
      background-image: url('./image/Wellness.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
    
  .custom-heading8-container {
      position: relative;
      background-image: url('./image/CareUHome.jpg'); /* ตั้งค่าภาพพื้นหลัง */
      background-size: cover; /* ทำให้ภาพครอบคลุมทั้งพื้นที่ */
      background-position: center; /* จัดภาพให้กลาง */
      height: 40px; /* ปรับความสูงของ div ที่ใช้แสดงข้อความ */
      display: flex;
      justify-content: center;
      align-items: center; /* จัดตำแหน่งข้อความให้ตรงกลาง */
    }
  }