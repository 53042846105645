.allproduct-container {
    margin-top: 20px;
  }
  
  
  .allproduct-scroll-container {
    display: flex;
    overflow-x: auto; /* ให้ scroll แนวนอน */
    padding: 10px 0;
    scroll-snap-type: x mandatory; /* เพิ่มการตั้งค่าให้สามารถเลื่อนได้ */
  }
  
  .allproduct-scroll-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* สีของ thumb */
    border-radius: 10px; /* ทำให้มุมของ scrollbar ป้าน */
  }
  
  .allproduct-scroll-container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* สีของ track (ที่เป็นพื้นหลังของ scrollbar) */
  }
  
  .allproduct-scroll {
    display: flex;
    flex-wrap: nowrap; /* ใช้ wrap เพื่อไม่ให้ card หยุดลง */
    gap: 50px; /* เพิ่มระยะห่างระหว่างการ์ด */
  }
  
  .allproduct-scroll-item {
    flex: 0 0 auto; /* ป้องกันไม่ให้ item ขยายเต็มที่ */
    scroll-snap-align: start; /* ทำให้การ์ดยึดตามการเลื่อน */
    margin-right: 100px;
  }

  /* AllProduct.css */
.allproduct-container .col-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.allproduct-container .col-6 .product-card-home {
  width: 100%; /* ขยายเต็มความกว้างของ col-6 */
  height: 100%; /* ถ้าต้องการขยายเต็มพื้นที่ */
}

  
  @media (max-width: 576px) {
    .allproduct-scroll-container {
        grid-template-columns: repeat(2, 1fr); /* แสดง 2 คอลัมน์สำหรับมือถือ */
    }
  
    .allproduct-card-container {
        max-width: 150px; /* ปรับขนาดของ card */
    }
  }

  
  