/* @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;700&family=Trirong:wght@400;700&display=swap');

body {
  font-family: 'Sarabun', serif;
}
.wishlist-cart-container a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #555;
  transition: transform 0.3s, color 0.3s, background-color 0.3s;
  padding: 10px 15px; /* เพิ่มพื้นที่ภายในลิงก์ */
  border-radius: 8px; /* ทำให้มุมมน */
}

.wishlist-cart-container a:hover {
  transform: scale(1.1);
}

.wishlist-cart-container a .anticon {
  font-size: 24px;
  margin-bottom: 5px;
}

.wishlist-action {
  background-color: #ffe4e1; /* สีพื้นหลังสำหรับ Wishlist */
}

.wishlist-action:hover {
  background-color: #ffb6c1; /* สีพื้นหลังเมื่อ hover */
}

.cart-action {
  background-color: #e0f7fa; /* สีพื้นหลังสำหรับ Cart */
}

.cart-action:hover {
  background-color: #b2ebf2; /* สีพื้นหลังเมื่อ hover */
}

.wishlist-cart-container a span {
  font-size: 14px;
  font-weight: 500;
}



.prompt-thin {
  /* font-family: 'Prompt', serif; */
  font-family: 'Sarabun', serif;
  font-weight: 100;
  font-style: normal;
}

.carousel-img-container {
  width: 100%; /* ให้ container มีขนาด 100% ของ parent */
  height: auto;
}

.carousel-img {
  max-width: 400px; /* ทำให้รูปปรับขนาดอัตโนมัติตาม container */
  height: auto; /* ป้องกันการบิดเบือนของรูป */
  object-fit: contain; /* ทำให้รูปแสดงภายในขอบเขตโดยไม่ถูกตัด */
  display: block; /* ป้องกันปัญหาการไม่แสดง */
}



/* สไตล์สำหรับคอนเทนเนอร์ */
/* ตั้งค่าพื้นฐานสำหรับแท็บ */


/* เปลี่ยนสีพื้นหลังและสีข้อความเมื่อแท็บถูกเลือก */
/* ใช้ .custom-tabs เพื่อให้เฉพาะแท็บใน Tabs ที่มี class นี้เท่านั้น */
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: black; /* เปลี่ยนพื้นหลังเป็นสีดำ */
  color: white; /* เปลี่ยนข้อความเป็นสีขาว */
}

/* เปลี่ยนสีข้อความของแท็บที่ไม่ได้เลือก */
.custom-tabs .ant-tabs-tab {
  color: #000; /* สีข้อความสำหรับแท็บที่ไม่ได้เลือก */
}

/* ปรับให้สีพื้นหลังของ tab-bar ไม่ซ้อนกับแถบอื่นๆ */
.custom-tabs .ant-tabs-card .ant-tabs-card-bar {
  border-bottom: 2px solid #ccc; /* เพิ่มขอบที่ด้านล่าง */
}






/* .carousel-img-container {
  width: 100%;
  padding-top: 100%; 
  position: relative;
}

.carousel-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
}


.carousel .thumb img {
  width: 100%; 
  height: auto; 
  object-fit: contain; 
} */

.original-price {
  text-decoration: line-through;
  color: red;
  margin-right: 10px;
}

.discounted-price {
  color: green;
}





.reviews-section {
  margin-top: 20px;
}

.reviews-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.review-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.review-author {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.review-rating {
  margin-bottom: 5px;
}

.review-comment {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
}

.review-time {
  font-size: 0.8rem;
  color: #888;
}


@media (max-width: 768px) {
  .single-product-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .carousel-img {
    max-width: 100%; /* ทำให้รูปปรับขนาดอัตโนมัติตาม container */
    height: auto; /* ป้องกันการบิดเบือนของรูป */
    object-fit: contain; /* ทำให้รูปแสดงภายในขอบเขตโดยไม่ถูกตัด */
    display: block; /* ป้องกันปัญหาการไม่แสดง */
  }
  .col-md-6 {
    margin-bottom: 20px;
  }
}

.card-tap {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* เพิ่มเงา */
  border-radius: 8px; /* ปรับให้ขอบมีความมน */
  padding: 10px; /* ปรับระยะห่างด้านใน */
  background-color: #fff; /* ให้พื้นหลังเป็นสีขาวเพื่อให้ดูชัดเจน */
}

.carousel .thumb {
  width: 60px; /* กำหนดขนาด thumbnails */
  height: 60px;
}



/* .carousel-img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
} */

.description {
  white-space: pre-line; /* บังคับให้เว้นบรรทัดตามที่มีในข้อความ */
  word-wrap: break-word; /* ทำให้คำยาวๆ แตกบรรทัด */
}


/* ปรับรูปแบบข้อความสำหรับ list-group-item */
.list-group-item {
  font-family: 'Sarabun', serif;
  font-size: 16px; /* ขนาดตัวหนังสือ */
  color: #333; /* สีข้อความ */
  border: none;
}

/* ปรับข้อความของ span ด้านขวา */
.list-group-item .float-end {
  font-family: 'Sarabun', serif;
  font-size: 14px; /* ขนาดตัวหนังสือของข้อมูลด้านขวา */
  color: #f031a0; /* สีข้อความ */
  border: none; /* เปลี่ยนสีเส้นขอบด้านล่าง */
}

/* กรณีต้องการปรับเฉพาะคำว่า "ราคา", "ขายแล้ว", "หมวดหมู่" */
.list-group-item:first-child {
  font-weight: bold; /* ตัวหนา */
  color: #333; /* สีฟ้าสำหรับหัวข้อ */
  border: none;
}

/* สำหรับส่วนของ original price และ discounted price */
.original-price {
  text-decoration: line-through; /* ขีดเส้นกลาง */
  color: #cf1515; /* สีเทา */
  border: none;
}

.discounted-price {
  font-weight: bold; /* ตัวหนา */
  color: #28a745; /* สีเขียว */
}

